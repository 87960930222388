import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/feature.css';
import aboutiu from '../assets/image/IU.png';

const AboutIU = () => {
  const titleStyle = { color: '#D8100B' };
  // const imagePath = about;
  return (
    <section style={{ backgroundImage: 'url(${imagePath})' }}>
      <Container className="container-style1">
        <Row className="flex-row-reverse align-items-center gx-70">
          <Col lg="6" xl>
            <img src={aboutiu} alt="about image" />
          </Col>
          <Col lg="6" xl="auto" className="wow fadeInUp" data-wow-delay="0.2s">
            <div className="about-box2">
              <h2 className="sec-title3 h1">About<span style={titleStyle}> Integrasia Utama</span></h2>
              <p style={{ fontSize: "20px", fontWeight: "600", color: "#252525" }}>
                The vision and purpose of the One Spirit Ecosystem is collaboration
              </p>
              <p>
                Our vision is to empower small businesses globally, guiding them towards Industry 4.0. We start with data digitalization,
                use analytical insights, and integrate predictive AI tailored to diverse sectors.
                Geospatial capability and IoT support are at our core,
                gathering field data for machine learning and AI.
                Overcoming remote data challenges, we enhance capabilities with scalable machine-to-machine networks.
                Our inclusive APIs and frameworks cater to any sector. Partnering with leading industries, we co-create bespoke solutions,
                adding value to processes and our platform. Join our One Spirit Ecosystem and experience mutual growth: Integration Brings Value.
              </p>
              <Row className="gx-0 align-items-center flex-row-reverse justify-content-end mt-sm-3 pt-sm-3 mb-30 pb-10">
                <Col sm="auto">
                  <p className="author-degi">Founder & CEO</p>
                  <h3 className="h5 author-name">Bayu Wedha</h3>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutIU;
