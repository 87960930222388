import React from "react";
import { Container, Row, Col } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/feature.css';
import curve from "../assets/image/curva.png";
import locationImg from "../assets/image/location.png";
import employeeImg from "../assets/image/employee.png";
import budgetImg from "../assets/image/budget.png";
import ganttImg from "../assets/image/gantt.png";
import kanbanImg from "../assets/image/kanban.png";

const OurFeature = () => {
  const titleStyle = { color: '#D8100B' };

  const features = [
    {
      img: curve,
      title: "S Curve",
      text: "Unleash your potential with S curve. Manage and predict potential overtime & over budget."
    },
    {
      img: locationImg,
      title: "Location",
      text: "Gain remote insight with map dashboard tagging and fencing as well as IoT Support*."
    },
    {
      img: employeeImg,
      title: "Employee Management",
      text: "Manage remote employees confidently with location capability, solve ghost workers and receive direct field reports."
    },
    {
      img: budgetImg,
      title: "Budget",
      text: "Transform your project's financial journey with comprehensive diagram analytics. Take control, set goals, thrive."
    },
    {
      img: ganttImg,
      title: "Gantt",
      text: "Experience our highly powerful gantt chart: critical pathing daily materials, and spent."
    },
    {
      img: kanbanImg,
      title: "Kanban",
      text: "Boost productivity and ensure faster delivery with continuous improvement. Easily manage and customize tasks linked to gantt milestones using drag-and-drop simplicity."
    },
  ];

  return (
    <section className="z-index-common mb-100">
      <Container className="wow fadeInUp" data-wow-delay="0.2s">
        <Row className="justify-content-center">
          <Col md="8" lg="8" className="text-center">
            <div className="title-area">
              <h2 className="sec-title3" style={titleStyle}>Our Feature</h2>
            </div>
          </Col>
          <Col lg="12">
            <Row className="vs-carousel feature-slide2 text-center text-xxl-start" data-slide-show="3" data-md-slide-show="2">
              {features.map((feature, index) => (
                <Col lg="4" md="6" className="mb-2" key={index}>
                  <div className="feature-style2">
                    <div className="feature-icon">
                      <img src={feature.img} alt={feature.title} />
                    </div>
                    <h3 className="feature-title h5" style={titleStyle}>{feature.title}</h3>
                    <p className="feature-text">{feature.text}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurFeature;
