import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import about from "../assets/image/8.png";
import about2 from "../assets/image/9.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/feature.css';

const AboutUs = () => {
    const titleStyle = { color: '#D8100B' };
    const titleStyleBlue = { color: '#122448' };
    return (
        <section className="space" style={{ backgroundImage: 'url("assets/img/bg/ab-bg-2-1.jpg")' }} id="aboutv2">
            <Container>
                <Row className="gx-60">
                    <Col xl="6" className="mb-50 mb-xl-0 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="img-box3">
                            <div className="img-1"><img src={about} alt="About image" /></div>
                            <div className="img-2"><img src={about2} alt="About image" /></div>
                            <div className="award-box">
                                <div className="award-box__shape"></div>
                                <div className="award-box__icon"><i className="fal fa-award"></i></div>
                                <div className="media-body">
                                    <p className="award-box__number">20+</p>
                                    <div className="award-box__text">Partner</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl="6" className="align-self-center text-center text-xl-start">
                        <span className="sec-subtitle">
                            <i className="fas fa-bring-forward"></i>
                            ABOUT OS<span style={titleStyle}>PRO</span>
                        </span>
                        <h2 className="sec-title3 h1">Connecting people & technology</h2>
                        <p className="mb-4 pb-2 pe-xl-5">Take control of your projects with our comprehensive website management solution. From planning to execution, our platform provides all the tools you need to streamline your project management process. With intuitive features, seamless collaboration, and advanced geospatial and IoT technology, you can effectively manage tasks, monitor workers and projects across various locations, track progress, and keep your team connected. Experience the power of efficient project management and unlock your team's full potential. Start optimizing your projects today with our website management platform.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default AboutUs;
